<template>
  <div
    :class="
      $route.path.includes('products') ? 'products-listing__wrapper2' : 'products-listing__wrapper'
    "
    style="width: 18%; display: flex; flex-direction: column; align-items: flex-start"
    :style="
      $route.path.includes('products')
        ? 'margin-top: 6px; width: 25%; display: flex; flex-direction: column; align-items: flex-start;'
        : 'margin-top: 6px; width: 25%; display: flex; flex-direction: column; align-items: flex-start;'
    "
  >
    <button
      v-if="$route.path.includes('products')"
      v-b-modal.modal-add-size
      class="button"
      style="border-radius: 6px"
      :disabled="!colorId"
      :class="{'button--disabled': (!colorId && getDressesSize.length == 0)}"
      @click="getMaterialVByMandP"
    >
      <b-icon-plus />
      {{ $t('AddSize') }}
    </button>
    <div
      class="table__header"
      style="margin-bottom: 15px !important; margin-top: 0px"
    >
      <button
        v-if="$route.path.includes('create-order') "
        v-b-modal.add-custom-order
        class="button"
        style="border-radius: 6px; width: auto; position: relative; width: 198px; "
        @click="rr"
      >
        <img
          src="../../assets/images/plus-circle.svg"
          alt="plus"
        >
        {{ $t('AddOrder') }}
        <span
          v-if="counter != 0"
          style="font-size: 13px; background: red; border-radius: 50%; width: 20px; height: 20px; position: absolute; top: -5px; right: -9px; "
        >{{ counter == 0 ? '' : counter }}</span>
      </button>
    </div>
    <div
      style="width: 100%;  padding-top: 16px"
      :class="{'button--disabled': getDressesSize.length == 0}"
      :style="$route.path.includes('products') ? 'margin-top:-3px;' : 'margin-top: -17px'"
    >
      <table
        class="team_table"
      >
        <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t('Size') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th
              v-if="$route.path.includes('products') && client=='FjollaNila'"
            >
              {{ $t('Stock') }}
            </th>
            <th
              v-if="$route.path.includes('products') && client !='FjollaNila'"
              colspan="2"
            >
              {{ $t('Edit') }}
            </th>
            <th
              v-if="$route.path.includes('create-order')"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getDressesSize"
            :key="item.inventoryProductId"
            class="hover-row"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            :style="classNameCh === item.inventoryProductId ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.size }}
            </td>
            <td style="width: 17%;">
              {{ numbersWithcomma(item.price) }} {{ item.currency }}
            </td>
            <td
              v-if="$route.path.includes('products') && client=='FjollaNila'"
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer; color:#141414 !important ; font-weight: 400;'
                  : 'padding: 5px; color:#141414 !important ; font-weight: 400;'
              "
              @click="showModal"
            >
              <b-button
                v-b-modal.add-direct-instock
                class="small"
                size="sm"
                @click="AddToCustom(item), edit(item)"
              >
                <span v-if="item.added">{{ $t('Added') }}</span>
                <span v-else> {{ $t('AddToStock') }}</span>
              </b-button>
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-edit-size
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer; color:#141414 !important ; font-weight: 400;'
                  : 'padding: 3px; color:#141414 !important ; font-weight: 400;'
              "
              @click="edit(item)"
            >
              <b-icon-pencil />
            </td>
            <td
              v-if="$route.path.includes('create-order')"
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer'
                  : 'padding: 5px'
              "
            >
              <b-button
                class="small"
                size="sm"
                style="border: none;width:150px;height: 30px;"
                @click="AddToCustom(item)"
              >
                <span v-if="item.added">{{ $t('Added') }}</span>
                <span v-else> {{ $t('AddToS') }}</span>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <AddSize
      :materials="materialId"
      :measure-item="measureId"
      :color-data="colorId"
      @variantMaterialData="variantMaterialData"
    /> -->
    <!-- <AddDirectInStock
      :color-data="colorId"
      :materials="materialId"
      :measure-item="measureId"
      :e-size="eSize"
      @AddDirectInStock="createOrderInStoreTemporary"
    /> -->
    <!-- <EditSize
      :color-data="colorId"
      :materials="materialId"
      :measure-item="measureId"
      :e-size="eSize"
      :pricess="prices"
      :materials-article-num="getMaterialsByArticleNum.productSingleMaterialVariantDtos"
      @updateSize="editSize"
    /> -->
    <CreateOrderModal
      ref="childComponentRef"
      :added-article="rows"
      :materials="getMaterialsByArticleNum"
      @createOrder="createOrderInStore"
      @incrementCounter="increment"
      @decrementCounter="decrement"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CreateOrderModal from '@/modals/CreateOrderModal.vue';
// import AddSize from '@/components/products/modals/AddSize.vue';
// import AddDirectInStock from '@/components/products/modals/AddDirectInStock.vue';
// import EditSize from '@/components/products/modals/EditSize.vue';
import jsPDF from 'jspdf';
import moment from 'moment';
import { client } from '../../domainConfig';
import 'jspdf-autotable';

export default {
  components: {
    // AddSize,
    // AddDirectInStock,
    CreateOrderModal,
    // EditSize,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    materialId: {
      type: [String, Object],
    },
    createOrder: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    colorId: {
      type: [String, Object],
    },
    measureId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      orderNumberClient: null,
      addressOfClient: null,
      phoneOfClient: null,
      emailOfClient: null,
      nameOfClient: null,
      initialId: '',
      materialPropId: '',
      modalVisible: false,
      client: client.clientName,
      status: true,
      size: '',
      prices: {
        price: 0,
        currency: '',
      },
      eSize: {
        size: '',
      },
      classNameCh: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      artNum: '',
      invProdId: '',
      counter: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getDressesSize',
      'getCustomOrders',
      'getMaterialsByArticleNum',
      'getResetStatus',
      'getDressColors',
      'getMeasermentsById',
      'getInvoiceByOrderNumber',
    ]),
  },
  watch: {
    getResetStatus(value) {
      if (value == true) { this.rows = []; this.counter = 0; this.resetCustomOrders2() }
    },
  },
  mounted() {
    // console.log('22', this.getMeasermentsById)
  },
  methods: {
    ...mapActions([
      'productSizes',
      'inventoryProductSizeFromColorId',
      'getInventoryProductsByColorId',
      'productsByArticleNumber',
      'addInventoryProductSize',
      'editInventoryProductSize',
      'addOrderInStore',
      'resetCustomOrders',
      'resetCustomOrders2',
      'getMaterialVariantsToMDCPagination',
      'resetDressColors',
      'resetSizes',
      'resetProductsByArticleNumber',
      'loadMeasermentsById',
      'addProductInStock',
      'addProductDirectInStock',
      'accountDetails',
      'loadInvoiceByOrderNumber',
    ]),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    showModal() {
      this.modalVisible = true;
    },
    edit(item) {
      this.prices.price = item.price
      this.prices.currency = item.currency
      this.eSize.size = item.size;
      this.invProdId = item.inventoryProductId;
      this.getMaterialVByMandP()
    },
    async editSize(obj) {
      await this.editInventoryProductSize({
        inventoryProductId: this.invProdId,
        object: obj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(this.colorId.mainDressCategoryColorId);
          this.productsByArticleNumber(this.artNum);
        },
      });
    },
    rr() {
      // this.loadOrderTypes()
      this.getMaterialVByMandP()
      if (this.getResetStatus == true) {
        this.rows = [];
      }
      this.accountDetails({ isActive: true })
      this.productSizes()
    },
    increment() {
      this.counter++;
    },
    decrement() {
      this.counter--;
    },
    async AddToCustom(item) {
      await this.productsByArticleNumber(item.articleNumber);
      const t = {
        twentyPrinciples: null,
        bodyMeasurements: {},
        itemi: item,
        pickUpDate: '',
        filledPickUpDate: true,
        showLengthError: false,
        showDressError: false,
        materials: this.getMaterialsByArticleNum,
        probeDates: [],
        paidPrice: 0,
        price: 0,
      }
      console.log(t)
      this.rows.push(t);
      this.counter++;
      this.resetCustomOrders2();
    },
    async createOrderInStore(obj) {
      await this.addOrderInStore({
        object: obj,
        successCallback: (response) => {
          this.downloadInvoice(response.data)
          // This function will be called on success
          this.$refs.childComponentRef.resetForm();
        },
      });
      this.resetDressColors()
      this.resetSizes()
      this.resetProductsByArticleNumber()
    },
    async createOrderInStoreTemporary() {
      await this.productsByArticleNumber(this.artNum);
      const object2 = []
      object2.push({
        bodyMeasurements: null,
        articleNumber: this.artNum,
        materialVariantDtos: this.getMaterialsByArticleNum,
      })
      const t = {
        articleNumber: this.artNum, productInStockMaterialVariantRequestDtos: object2, message: '', size: this.size,
      }
      this.rows.push(t);
      await this.addProductDirectInStock(t);
      this.resetProductsByArticleNumber()
      this.classNameCh = '';
    },
    async variantMaterialData(filterObj) {
      // eslint-disable-next-line no-unused-vars
      const addInvertoryObj = {
        mainDressCategoryId: this.materialId.id,
        mainDressCategoryColorId: this.colorId.mainDressCategoryColorId,
        ...filterObj,
      };
      await this.addInventoryProductSize({
        object: addInvertoryObj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(addInvertoryObj.mainDressCategoryColorId);
        },
      });
    },

    async matProps(mat) {
      // this.$emit('inside', mat)
      this.materialPropId = mat.materialId;
      this.classNameCh = mat.inventoryProductId;
      this.size = mat.size;
      this.artNum = mat.articleNumber;
      await this.productsByArticleNumber(mat.articleNumber);
    },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      });
    },
    downloadInvoice(value) {
      this.orderNumberClient = value
      this.loadInvoiceByOrderNumber({
        orderNumber: value,
      })
        .then(() => {
          const invoice = this.getInvoiceByOrderNumber;

          // Generate table content for probe dates
          const tableContent = this.generateInvoiceTableContent(invoice);
          const tableContent2 = this.generateInvoiceTableContent(invoice);

          // Generate PDF with the table content
          const pdfDocument = this.generateInvoice(tableContent, tableContent2, 800);

          // Save the PDF
          const pdfBlob = pdfDocument.output('blob');
          const blobUrl = URL.createObjectURL(pdfBlob);

          // Save the Blob URL to localStorage or sessionStorage
          sessionStorage.setItem('pdfBlobUrl', blobUrl);
          sessionStorage.setItem('pathName', this.$route.path);

          // Navigate to the PDF preview page using Vue Router
          this.$router.push({ name: 'Pdf Preview' });
        })
        .catch((error) => {
          console.error('Error downloading the data:', error);
          // this.changeLoadingtoTrue(false);
        });
    },
    previewPdf(blobUrl) {
      // Assuming you have an iframe in your HTML to show the PDF preview
      const iframe = document.getElementById('pdf-preview');
      if (iframe) {
        iframe.src = blobUrl;
      } else {
        console.error('PDF preview iframe not found.');
      }
    },
    generateInvoiceTableContent(invoice) {
      // Define table headers
      const headers = [`${this.$t('ProductName')}`, `${this.$t('Price')}`, `${this.$t('Discount')}`, `${this.$t('PriceWithDiscount')}`, `${this.$t('PaidPrice')}`, `${this.$t('RemainingAmount')}`];
      const array = invoice.invoiceOrderItemsResponseDtos
      const array1 = invoice
      const array2 = [array1]

      // Map probe dates to table body
      const body = array.map((value) => {
        const discountedPrice = (value.price - value.discount).toFixed(2);
        const remainingBill = (value.price - value.discount - value.paidAmount).toFixed(2);
        this.emailOfClient = invoice.invoiceHeaderClientDetailsResponseDto.email
        this.phoneOfClient = invoice.invoiceHeaderClientDetailsResponseDto.telephoneNumber
        this.addressOfClient = {
          street: invoice.invoiceHeaderClientDetailsResponseDto.addressDto ? invoice.invoiceHeaderClientDetailsResponseDto.addressDto.street : 'Not Found',
          city: invoice.invoiceHeaderClientDetailsResponseDto.addressDto ? invoice.invoiceHeaderClientDetailsResponseDto.addressDto.city : 'Not Found',
        }
        this.nameOfClient = invoice.invoiceHeaderClientDetailsResponseDto.clientName
        this.remark = invoice.invoiceRemark
        return [
          value.articleName,
          value.price.toFixed(2),
          value.discount.toFixed(2),
          discountedPrice,
          value.paidAmount.toFixed(2),
          remainingBill,
        ]
      });
      const headers1 = [
        `${this.$t('PromoCode')}`,
        `${this.$t('TotalPaid')}`,
        `${this.$t('TotalPrice')}`,
        `${this.$t('VAT 18%')}`,
        `${this.$t('RemainingPrice')}`,
      ];

      const body1 = array2.map((value) => {
        const vat = (value.totalPrice * 0.18).toFixed(2);
        const remainingPrice = (value.totalPrice - value.totalPaidAmount).toFixed(2); // Calculate remaining price

        return [
          `${this.client} - #5930FSG`,
          value.totalPaidAmount.toFixed(2),
          value.totalPrice.toFixed(2),
          vat,
          remainingPrice,
        ];
      });

      console.log('body1', body1);
      return {
        headers,
        body,
        headers1,
        body1,
      };
    },
    generateInvoice(tableContent, tableContent2, xharraIcon, swisMade, customWidth) {
      const { headers, body } = tableContent;
      const { headers1, body1 } = tableContent2;



      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape if you want a wider document
        unit: 'pt', // Use points as the unit of measurement
        format: [750, 900],
      });

      // Set custom width after creating the doc instance
      doc.setProperties({
        format: [customWidth, doc.internal.pageSize.getHeight()], // Set custom width
      });

      const iconWidth = 90;
      const iconHeight = 120;
      const iconY = 0;
      const iconX = 40;
      doc.addImage(xharraIcon, 'PNG', iconX, iconY, iconWidth, iconHeight);

      let url;
      let address;
      if (this.client == 'DrenushaXharra') {
        url = 'www.drenushaxharra.com';
        address = 'Kostori, Entrance C, Prishtinë, 10000';
      } else if (this.client == 'FjollaNila') {
        url = 'www.fjollanila.com';
        address = 'Elena Gjika, Pejë 30000';
      } else if (this.client == 'ValdrinSahiti') {
        url = 'www.valdrinsahiti.com'
        address = 'Autostrada Prishtinë-Ferizaj, km i 2të, 10000'
      } else if (this.client == 'WaloneFashion') {
        url = 'www.walonefashion.com'
        address = 'PRISHTINE-FERIZAJ, Km7, Prishtina 10000'
      } else if (this.client == 'MinnaFashion') {
        url = 'www.minna.shop'
        address = 'Prishtinë-Ferizaj highway 5km, Prishtinë, Kosova'
      } else {
        url = 'http://localhost:8080/';
        address = 'Spim Fashion Group';
      }
      const urlX = 40;
      const pageHeight = doc.internal.pageSize.getHeight();
      const urlY = pageHeight - 40;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(url, urlX, urlY, { align: 'left' });

      const addressX = 40;
      const addressY = pageHeight - 20;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(address, addressX, addressY, { align: 'left' });

      const addressX1 = 40;
      const addressY1 = pageHeight - 70;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('InoviceRemark')} : `, addressX1, addressY1, { align: 'left' });

      doc.setTextColor(255, 0, 0);
      doc.text(`${this.remark}`, addressX1 + doc.getTextWidth(`${this.$t('InoviceRemark')} : `), addressY1, { align: 'left' });



      const staticContent = () => {
      };

      // Add the title
      const title = this.$t('Invoice');
      const titleY = doc.internal.pageSize.getHeight() - 670;
      const titleX = doc.internal.pageSize.getWidth() - 120;
      doc.setFontSize(45);
      doc.setFont('Arial', 'bold');
      doc.setTextColor(11, 19, 84);
      doc.text(title, titleX, titleY, { align: 'center' });

      doc.setFont('Arial', 'normal');
      // Add the author
      const authorX = 150;
      const authorY = 76;
      doc.setFontSize(22);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('Spim Invoice')}`, authorX, authorY);

      const author2X = 150;
      const author2Y = 100;
      doc.setFontSize(16);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('Spim Dega në Kosovë')}`, author2X, author2Y);

      const author3X = 40;
      const author3Y = 170;
      doc.setFontSize(15);
      doc.setFont('Arial', 'bold');
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('From')}:`, author3X, author3Y);

      doc.setFont('Arial', 'normal');

      const author4X = 92;
      const author4Y = 170;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.client}`, author4X, author4Y);


      const author6X = 410;
      const author6Y = 170;
      doc.setFontSize(15);
      doc.setFont('Arial', 'bold');
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('To')}:`, author6X, author6Y);

      doc.setFont('Arial', 'normal');

      const author7X = 440;
      const author7Y = 170;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.nameOfClient}`, author7X, author7Y);

      const author8X = 440;
      const author8Y = 190;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.addressOfClient.city},${this.addressOfClient.street}`, author8X, author8Y);

      const author9X = 440;
      const author9Y = 210;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.emailOfClient}`, author9X, author9Y);

      const author10X = 440;
      const author10Y = 230;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.phoneOfClient}`, author10X, author10Y);

      const author11X = 745;
      const autho11Y = 170;
      doc.setFontSize(15);
      doc.setFont('Arial', 'bold');
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('Date')}:`, author11X, autho11Y);

      doc.setFont('Arial', 'normal');

      const author12X = 790;
      const author12Y = 170;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${moment().format('DD-MM-YYYY')}`, author12X, author12Y);

      const author13X = 680;
      const autho13Y = 190;
      doc.setFontSize(15);
      doc.setFont('Arial', 'bold');
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.$t('OrderNumber')}:`, author13X, autho13Y);

      doc.setFont('Arial', 'normal');

      const author14X = 790;
      const author14Y = 190;
      doc.setFontSize(15);
      doc.setTextColor(11, 19, 84);
      doc.text(`${this.orderNumberClient}`, author14X, author14Y);



      // Add the table
      const tableOptions = {
        startY: authorY + 190, // Adjust the vertical position of the table
        time: moment().format('DD-MM-YYYY'),
        head: [headers],
        body,
        headStyles: {
          fillColor: [234, 236, 240], // Set the background color for the header
          textColor: [11, 19, 84], // Set the text color for the header
          halign: 'center',
        },
        bodyStyles: {
          textColor: [26, 26, 26], // Set the text color for the body
          lineWidth: 0.1, // Set the width of the border lines
          lineColor: [234, 236, 240], // Set the color of the border lines
        },
        columnStyles: {
          // Adjusting individual column styles for center alignment
          0: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          1: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          2: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          3: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          4: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          5: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
          6: { columnWidth: doc.internal.pageSize.getWidth() / 7.7, halign: 'center', fillColor: [255, 255, 255] },
        },
      };

      const tableOptions2 = {
        startY: authorY + 400, // Adjust the vertical position of the table
        time: moment().format('DD-MM-YYYY'),
        head: [headers1],
        body: body1,
        headStyles: {
          fillColor: [234, 236, 240], // Set the background color for the header
          textColor: [11, 19, 84], // Set the text color for the header
          halign: 'center',
        },
        bodyStyles: {
          textColor: [26, 26, 26], // Set the text color for the body
          lineWidth: 0.1, // Set the width of the border lines
          lineColor: [234, 236, 240], // Set the color of the border lines
        },
        columnStyles: {
          // Adjusting individual column styles for center alignment
          1: { columnWidth: doc.internal.pageSize.getWidth() / 5.7, halign: 'center', fillColor: [255, 255, 255] },
          2: { columnWidth: doc.internal.pageSize.getWidth() / 5.7, halign: 'center', fillColor: [255, 255, 255] },
          0: { columnWidth: doc.internal.pageSize.getWidth() / 5.7, halign: 'center', fillColor: [255, 255, 255] },
          3: { columnWidth: doc.internal.pageSize.getWidth() / 5.7, halign: 'center', fillColor: [255, 255, 255] },
          4: { columnWidth: doc.internal.pageSize.getWidth() / 5.7, halign: 'center', fillColor: [255, 255, 255] },
        },
      };


      doc.autoTable(tableOptions);
      doc.autoTable(tableOptions2);

      staticContent();

      return doc;
    },
  },
};
</script>

<style lang="scss">

.team_table td {
  padding: 11px 6px !important;
}

.small:hover {
  border: 0.1px solid #FF274F !important;
  background: white;
  color: #FF274F;
  transition: ease-in-out 0.3s;
  width: 150px;
  height: 30px;
}

.modal-backdrop
{
    opacity:0.5 !important;
}
.modal-backdrop.show {
    opacity: 0.7;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.products-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
}
// .button {
//   margin-bottom: 0px !important;
//   width: 195.22px;
//   background: #FF274F !important;
//   color: #f4f1ed;
//   border-color: transparent;
// }

.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .products-listing__wrapper {
    width: 100% !important;
  }
}
@media screen and (max-width: 1400px) {
  .products-listing__wrapper {
    width: 100% !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 1200px){
  .products-listing__wrapper{
    width: 100% !important;
    margin-right: 15px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1100px){
  .products-listing__wrapper{
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px;
  }
}

@media screen and (max-width: 896px) {
  .products-listing__wrapper {
    width: auto !important;
    flex-basis: 47%;
  }
}
</style>
